import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useAccountStore from 'store/accountStore';
import colors from 'styles/colors';
import ColoredButton from '../ColoredButton';

const ApiKeyButton = () => {
  const [clicked, setClicked] = useState(false);
  const apiKey = useAccountStore((state) => state.activeAccount.sdkToken);

  const handleApiTokenClick = () => {
    setClicked(true);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(apiKey).then(() => {
      setClicked(false);
    });
  };

  return !clicked ? (
    <ColoredButton
      buttonSx={{
        fontSize: '14px',
        paddingTop: '11px',
        paddingBottom: '11px',
      }}
      onClick={handleApiTokenClick}
    >
      See API token
    </ColoredButton>
  ) : (
    <Box
      sx={{
        padding: '1px',
        background: 'linear-gradient(259.41deg, #0060FA -27.53%, #CC74F5 126.63%)',
        display: 'inline-block',
        borderRadius: '24px',
      }}
    >
      <Box
        sx={{
          padding: '9px 12px',
          borderRadius: '24px',
          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #06061F',
        }}
      >
        <Typography variant="input" sx={{ color: colors.text.primary, fontFamily: 'monospace' }}>
          {apiKey}
        </Typography>
        <ContentCopyIcon
          onClick={handleCopyClick}
          sx={{
            color: colors.primary.main,
            fontSize: '16px',
            marginLeft: '5px',
            marginTop: '3px',
            cursor: 'pointer',
          }}
        />
      </Box>
    </Box>
  );
};

export default ApiKeyButton;

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const ColoredButton = ({
  children, disabled, sx, buttonSx, ...props
}) => (
  <Box
    sx={{
      padding: '1px',
      background: 'linear-gradient(259.41deg, #0060FA -27.53%, #CC74F5 126.63%)',
      display: 'inline-block',
      borderRadius: '24px',
      ...(disabled ? { borderColor: '#687487' } : {}),
      ...sx,
    }}
  >
    <Button
      disableRipple
      sx={
          {
            padding: '8px 17px',
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #06061F',
            color: '#FFFFFF',
            '&:hover': {
              background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11)), #06061F',
            },
            '&:active': {
              background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #06061F',
            },
            ...(disabled ? {
              background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #06061F',
              color: '#687487',
            } : {}),
            ...buttonSx,
          }
        }
      {...props}
    >
      {children}
    </Button>
  </Box>
);
export default ColoredButton;

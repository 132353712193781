import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import colors from 'styles/colors';

const styles = {
  secondaryAction: {
    fontSize: '16px',
    marginRight: '16px',
  },
};

const ConfirmationDialog = ({
  onConfirm,
  onCancel,
  onClose,
  title,
  confirmText = 'Submit',
  cancelText = 'Cancel',
  children,
  reversed = false,
}) => {
  const handleConfirm = () => {
    onConfirm();
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <DialogTitle>
        <Typography variant="h6" color={colors.text.primary}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        {children}
      </DialogContent>
      <DialogActions>
        {reversed ? (
          <>
            <Button
              type="secondary"
              variant="outlined"
              sx={styles.secondaryAction}
              onClick={handleConfirm}
            >
              {confirmText}
            </Button>
            <Button type="primary" variant="contained" onClick={onCancel}>
              {cancelText}
            </Button>
          </>
        ) : (
          <>
            <Button
              type="primary"
              variant="outlined"
              sx={styles.secondaryAction}
              onClick={handleCancel}
            >
              {cancelText}
            </Button>
            <Button type="primary" variant="contained" onClick={handleConfirm}>
              {confirmText}
            </Button>
          </>
        )}
      </DialogActions>
    </>
  );
};

export default ConfirmationDialog;

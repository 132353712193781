import { toast } from 'react-toastify';
import Notification from 'components/system/Notification';

const options = {
  autoClose: false,
  hideProgressBar: false,
  position: toast.POSITION.BOTTOM_RIGHT,
  pauseOnHover: true,
  pauseOnFocusLoss: false,
};

const withNotification = (Component) => ({ ...componentProps }) => {
  const showSuccessNotification = (message, action, customOptions = {}) => {
    console.log('success');
    return toast
      .success(
        (
          <Notification
            message={message}
            action={action}
          />
        ), {
          ...options,
          className: 'toast-success-container toast-success-container-after toast-icon',
          progressClassName: {
            background: '#81C784',
          },
          ...customOptions,
        },
      );
  };

  const showErrorNotification = (message, action, customOptions = {}) => toast
    .error(
      (
        <Notification
          message={message}
          action={action}
        />
      ), {
        ...options,
        className: 'toast-error-container toast-error-container-after toast-icon',
        progressClassName: {
          background: '#E57373',
        },
        ...customOptions,
      },
    );

  const showInfoNotification = (message, action, customOptions = {}) => {
    console.log('info');
    return toast
      .info(
        (
          <Notification
            message={message}
            action={action}
          />
        ), {
          ...options,
          className: 'toast-info-container toast-info-container-after toast-icon',
          progressClassName: {
            background: '#998CFF',
          },
          progressStyle: {
            background: '#998CFF',
          },
          ...customOptions,
        },
      );
  };

  return (
    <Component
      showSuccessNotification={showSuccessNotification}
      showErrorNotification={showErrorNotification}
      showInfoNotification={showInfoNotification}
      {...componentProps}
    />
  );
};

export default withNotification;

const colors = {
  linear_gradient: 'linear-gradient(259.41deg, #0060FA -27.53%, #CC74F5 126.63%)',
  radial_gradient: 'background: radial-gradient(72.31% 330.56% at 71.51% -102.31%, #0060FA 0%, #CC74F5 100%)',
  text: {
    primary: '#F2F5FA',
    secondary: '#A9B0BC',
    tetriary: '#687487',
    qaterniary: '#2C313A',
    disabled: 'rgba(242, 245, 250, 0.5)',
  },
  primary: {
    main: '#605EF5',
    dark: '#0F34C1',
    light: '#998CFF',
    shade_8p: 'rgba(96, 94, 245, 0.08)',
    shade_16p: 'rgba(96, 94, 245, 0.16)',
    shade_30p: 'rgba(96, 94, 245, 0.3)',
    shade_50p: 'rgba(96, 94, 245, 0.5)',
  },
  success: {
    main: '#66BB6A',
    dark: '#388E3C',
    light: '#81C784',
    shade_8p: 'rgba(102, 187, 106, 0.08)',
    shade_12p: 'rgba(102, 187, 106, 0.12)',
    shade_30p: 'rgba(102, 187, 106, 0.3)',
  },
  error: {
    main: '#F44336',
    dark: '#D32F2F',
    light: 'rgba(229, 115, 115, 0.87)',
    shade_8p: 'rgba(244, 67, 54, 0.08)',
    shade_12p: 'rgba(244, 67, 54, 0.12)',
    shade_30p: 'rgba(244, 67, 54, 0.3)',
  },
  background: {
    main: '#060E1F',
    secondary: '#182341',
    paper_5p: 'linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #060E1F',
    paper_7p: 'linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), #060E1F',
    paper_8p: 'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #060E1F',
    paper_9p: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #060E1F',
    paper_11p: 'linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11)), #060E1F',
    paper_12p: 'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #060E1F',
    paper_14p: 'linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #060E1F',
    paper_15p: 'linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #060E1F',
    paper_16p: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #060E1F',
  },
  border: {
    border_5p: 'rgba(255, 255, 255, 0.05)',
    border_10p: 'rgba(255, 255, 255, 0.1)',
    border_15p: 'rgba(255, 255, 255, 0.15)',
  },
  contrast: {
    main: 'rgba(255, 255, 255, 0.95)',
    secondary: 'rgba(0, 0, 0, 0.87)',
  },
};

export default colors;

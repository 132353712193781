import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useModalContext } from 'components/system/ModalContext';
import { AuthForm } from 'components/pages/Auth';
import useQueryParams from 'hooks/useGetSearchParam';
import { auth, onAuthStateChanged, useAuthState } from 'api/services/firebase';

const withSignInDialog = (Component) => ({ ...props }) => {
  const { openModal, closeModal } = useModalContext();
  const { useGetSearchParam, useRemoveSearchParam } = useQueryParams();

  const param = useGetSearchParam('open');

  const [user] = useAuthState(auth);
  const isGuest = !user;

  const handleClose = useCallback(
    () => useRemoveSearchParam('open'),
    [useRemoveSearchParam],
  );

  const openSignInDialog = (props = {}) => {
    openModal({
      content: (
        <AuthForm
          view="signIn"
          handleSubmitCallback={closeModal}
          {...props}
        />

      ),
      onCloseCallback: handleClose,
    });
  };

  useEffect(() => {
    if (param === 'login' && isGuest) {
      openSignInDialog(true);
    }
    const unsub = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        closeModal();
      }
    });
    return unsub;
  }, [isGuest, param]);

  return (

    <Component
      {...props}
      openSignInDialog={openSignInDialog}
    />
  );
};

export default withSignInDialog;

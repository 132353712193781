import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const ContentLoading = ({ sx, size }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      ...sx,
    }}
  >
    <CircularProgress size={size} />
  </Box>
);

export default ContentLoading;

import DividerGeneric from '@mui/material/Divider';

const Divider = (props) => (
  <DividerGeneric
    sx={{
      background: 'rgba(255, 255, 255, 0.1)',
    }}
    {...props}
  />
)

export default Divider;

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import colors from 'styles/colors';

export const JobTerminatedStatusIcon = () => (
  <CancelOutlinedIcon
    sx={{ fontSize: '14px', color: colors.error.light, marginRight: '10px' }}
  />
);

export const JobProgressStatusIcon = () => (
  <AccessTimeOutlinedIcon
    sx={{ fontSize: '14px', color: colors.primary.light, marginRight: '10px' }}
  />
);

export const JobCompletedStatusIcon = () => (
  <CheckCircleOutlineIcon
    sx={{ fontSize: '14px', color: colors.success.light, marginRight: '10px' }}
  />
);

export const roles = {
  guest: 'guest',
  user: 'user',
  teamMember: 'member',
  teamOwner: 'owner',
  teamAdmin: 'admin',
  isTeamUser: (role) => [roles.teamMember, roles.teamOwner, roles.teamAdmin].includes(role),
};

export const permissions = {
  'team.view': [roles.teamOwner, roles.teamAdmin, roles.teamMember],
  'team.create': [roles.user], // for now only users can create a team and only once
  'team.edit': [roles.teamOwner],
  'team.member.remove': [roles.teamOwner],
  'team.member.invite': [roles.teamOwner],
  'team.apply': [roles.guest],
  'jobs.team': [roles.teamAdmin, roles.teamOwner, roles.teamMember],
  'jobs.team.search': [roles.teamAdmin, roles.teamOwner],
  'jobs.user': [roles.user, roles.guest],
  'promo.banner': [roles.user, roles.guest],
  'billing.team.view': [roles.teamAdmin, roles.teamOwner, roles.teamMember],
  'billing.team.edit': [roles.teamOwner],
  'billing.user': [roles.user],
};

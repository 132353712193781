import { Helmet } from 'react-helmet';
import favicon from 'assets/images/bq_favicon.png';
import { auth, onAuthStateChanged } from 'api/services/firebase';
import { useEffect } from 'react';

const HelemtContainer = () => {
  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        window.intercomSettings = {
          api_base: 'https://api-iam.intercom.io',
          app_id: 'nyakzu9o',
          name: user.displayName,
          email: user.email,
        };
      } else {
        window.intercomSettings = {
          api_base: 'https://api-iam.intercom.io',
          app_id: 'nyakzu9o',
          name: '',
          email: '',
        };
      }
    });
    return unsub;
  }, []);

  return (
    <Helmet>
      <script>
        {/* INTERCOM scripts */}
        {`
        window.intercomSettings = { api_base: "https://api-iam.intercom.io", app_id: "nyakzu9o" };
      `}
      </script>
      <script>
        {/* INTERCOM scripts */}
        {`
        (function(){var w=window;var ic=w.Intercom;
          if(typeof ic==="function"){ic('reattach_activator');
          ic('update',w.intercomSettings);}else{var d=document;
            var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};
            w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';
            s.async=true;s.src='https://widget.intercom.io/widget/nyakzu9o';
            var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};
            if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);
          }else{w.addEventListener('load',l,false);}}})();
      `}
      </script>

      <script>
        {/* GOOGLE TAG MANAGER scripts */}
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.GOOGLE_TAG_MANAGER_ID}');
        `}
      </script>
      {process.env.ENVIRONMENT === 'production' && (
        <script>
          {/* HOTJAR Tracking Code for app.bluequbit.io */}
            {`(function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:3504963,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
        </script>
      )}
      {process.env.ENVIRONMENT === 'development' && (
        <script>
          {/* HOTJAR Tracking Code for dev.bluequbit.io */}
          {`(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:3497202,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
        </script>
      )}
      <link rel="shortcut icon" type="image/png" href={favicon} />
    </Helmet>
  );
};

export default HelemtContainer;

import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { logout } from 'api/services/firebase';
import colors from 'styles/colors';
import useAccountStore from 'store/accountStore';

const UserInfo = ({ isVerified, isErrored, onMenuClick }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const open = () => false;

  const isTeamAvailable = useAccountStore((state) => state.account.team);

  const userMenu = useMemo(() => [
    {
      label: 'Account Settings',
      handleClick: () => { navigate('settings'); },
    },
    {
      label: 'Team Settings',
      handleClick: () => { navigate('team-settings'); },
      isDisabled: process.env.TEAM_SETTINGS !== 'true' || !isTeamAvailable,
    },
    {
      label: 'Billing',
      handleClick: () => { navigate('billing'); },
    },
    {
      label: 'Terms Of Use',
      handleClick: () => { navigate('terms-of-use'); },
    },
    {
      label: 'Privacy Policy',
      handleClick: () => { navigate('privacy-policy'); },
    },
    {
      label: 'Contact Us',
      handleClick: () => { navigate('contact-us'); },
    },
    {
      label: 'Logout',
      handleClick: async () => {
        await logout();
        navigate('/');
        queryClient.clear();
      },
    },
  ], [navigate]);

  const restrictedUserMenu = useMemo(() => [
    {
      label: 'Logout',
      handleClick: logout,
    },
  ], []);

  const menu = isVerified && !isErrored ? userMenu : restrictedUserMenu;

  return (
    <MenuList id="menu-list-grow">
      {
        menu.map(({ isDisabled = false, label, handleClick }) => (!isDisabled ? (
          <MenuItem
            key={label}
            onClick={() => {
              onMenuClick();
              handleClick();
            }}
            sx={{
              margin: '8px 8px 0',
              padding: '10px',
              '&:hover': {
                background: colors.background.paper_8p,
                borderRadius: '12px',
              },
            }}
          >
            <Typography variant="caption" sx={{ color: colors.text.primary }}>
              {label}
            </Typography>
          </MenuItem>
        ) : null))
      }
    </MenuList>
  );
};

export default UserInfo;

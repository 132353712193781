import Typography from '@mui/material/Typography';
import ConfirmationDialog from 'components/system/ConfirmationDialog';

const JobNotebookStopConfirmation = ({ onConfirm, onCancel, onClose }) => (
  <ConfirmationDialog
    title="Stop Notebook"
    onCancel={onCancel}
    onClose={onClose}
    onConfirm={onConfirm}
    confirmText="Stop"
    reversed
  >
    <Typography variant="body2">
      Are you sure you want to shut down the machine? Make sure to save your work.
    </Typography>
  </ConfirmationDialog>
);

export default JobNotebookStopConfirmation;

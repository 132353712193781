import axios from 'axios';
import applyConverter from 'axios-case-converter';
import { auth } from 'api/services/firebase';
import { getSavedTeamId, getSavedAccountMode } from 'utils/helpers';
import { TEAM_PROFILE } from 'utils/constants';

const DEQART_BASE_URL = `${process.env.BQ_API_URL}/api/v1`;

const getAuthToken = async () => {
  try {
    const user = auth.currentUser;
    const token = await user?.getIdToken();
    return `Frontend ${token}`;
  } catch (err) {
    console.log('getAuthToken', err);
    return err;
  }
};

const getUserId = () => {
  const user = auth.currentUser;

  return user?.uid;
};

const axiosInstance = axios.create({
  baseURL: DEQART_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const attachTeamId = async (config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = await getAuthToken();
  const userId = getUserId();

  if (getSavedAccountMode(userId) === TEAM_PROFILE && getSavedTeamId(userId)) {
    // eslint-disable-next-line no-param-reassign
    config.headers['X-Team-Id'] = getSavedTeamId(userId);
  } else {
    // eslint-disable-next-line no-param-reassign
    delete config.headers['X-Team-Id'];
  }
  return config;
};

axiosInstance.interceptors.request.use(attachTeamId);

const axiosDeqart = applyConverter(axiosInstance, {
  caseFunctions: {
    camel: (input) => (
      (input.charAt(0).toLowerCase() + input.slice(1))
        .replace(
          /[-_](.)/g,
          (match, group) => group.toUpperCase(),
        )
    ),
  },
});

export const axiosDeqartSimple = axios.create({
  baseURL: DEQART_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosDeqartSimple.interceptors.request.use(attachTeamId);

export default axiosDeqart;

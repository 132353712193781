import React, { useContext, createContext, useState } from 'react';

const ModalStore = () => {
  const [content, setModalContent] = useState(null);
  const [paperProps, setPaperProps] = useState({});
  const [isOpened, setIsOpened] = useState(false);

  const openModal = ({ content, modalPaperProps = {} }) => {
    setIsOpened(true);
    setPaperProps(modalPaperProps);
    setModalContent(content);
  };

  const closeModal = () => {
    if (content?.onCloseCallback) {
      content?.onCloseCallback();
    }

    setIsOpened(false);
    setModalContent(null);
  };

  return {
    isOpened,
    content,
    setModalContent,
    openModal,
    closeModal,
    paperProps,
    setPaperProps,
  };
};

const ModalContext = createContext(null);

export const useModalContext = () => {
  const store = useContext(ModalContext);

  if (!store) {
    throw new Error('Cannot use `useModalContext` outside of a ModalContextProvider');
  }

  return store;
};

export const ModalContextConsumer = ({ ChildComponent, ...props }) => (
  <ModalContext.Consumer>
    {(store) => <ChildComponent store={store} {...props} />}
  </ModalContext.Consumer>
);

export const ModalContextProvider = ({ children }) => (
  <ModalContext.Provider value={ModalStore()}>
    {children}
  </ModalContext.Provider>
);

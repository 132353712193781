import NotebookServerError from 'utils/NotebookServerError';
import axiosDeqart from '../axios';

export const getNotebookServer = async () => {
  try {
    const { data } = await axiosDeqart.get('/jupyter-servers/my-server');
    const {
      domain, port, status, token, publicName,
    } = data[0] ? data[0].data : {};

    if (status === 'ERRORED') {
      throw new NotebookServerError('Notebook Server is not available', data);
    }

    if (status === 'ON') {
      return {
        url: `https://${domain}/${publicName}/${port}/lab/?token=${token}`,
        status,
      };
    }
    return { status };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const enableNotebookServer = async () => {
  try {
    const { data } = await axiosDeqart.post('/jupyter-servers');

    if (data.data.status === 'ERRORED') {
      throw new NotebookServerError('Notebook Server is not available', data);
    }

    return !!(data.data.port) && (data.data.status === 'OFF' || data.data.status === 'TURNING_ON');
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const submitHybridJob = async (path) => {
  try {
    const { data } = await axiosDeqart.post('/jupyter-servers/jupyter-jobs', {
      jobType: 'SMALL1',
      fileName: path,
      fileServerId: 'task-runner-develop-small1-0',
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const stopNotebookServer = async () => {
  try {
    await axiosDeqart.patch('/jupyter-servers/my-server');
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

import { initializeApp } from 'firebase/app';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  sendEmailVerification,
  applyActionCode,
  checkActionCode,
  verifyPasswordResetCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  updateProfile,
  getIdToken,
  onAuthStateChanged,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();

const registerWithEmailAndPassword = async ({ name, email, password }) => {
  try {
    const response = await createUserWithEmailAndPassword(auth, email, password);
    await updateProfile(response.user, { displayName: name });
    await sendEmailVerification(response.user);
    return response;
  } catch (err) {
    console.error(err);
    if (err.code === 'auth/email-already-in-use') {
      return {
        errors: {
          email: 'This email has been used already.',
        },
      };
    }

    return err;
  }
};

const signInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
  } catch (err) {
    console.error(err);
  }
};

const logInWithEmailAndPassword = async ({ email, password }) => {
  try {
    const response = await signInWithEmailAndPassword(auth, email, password);
    return response;
  } catch (error) {
    if (error.code === 'auth/user-not-found') {
      return {
        errors: {
          email: 'Email was not found',
        },
      };
    }

    if (error.code === 'auth/user-disabled') {
      return {
        errors: {
          email: 'Your account has been disabled. To request reactivation, please contact our support team.',
        },
      };
    }

    if (error.code === 'auth/wrong-password') {
      return {
        errors: {
          password: 'Password is not correct',
        },
      };
    }

    throw error;
  }
};

const sendPasswordReset = async ({ email }) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return {
      isLoading: false,
      success: true,
    };
  } catch (error) {
    console.error(error);
    return {
      isLoading: false,
      success: false,
      error: error.message,
    };
  }
};

const logout = async () => {
  await signOut(auth);
};

export {
  getAuth,
  auth,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  useAuthState,
  applyActionCode,
  verifyPasswordResetCode,
  checkActionCode,
  confirmPasswordReset,
  sendPasswordResetEmail,
  getIdToken,
  onAuthStateChanged,
  sendEmailVerification,
};

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';

const Input = ({
  name, value, label, error, onChange, sx, multiline, startAdornment, ...rest
}) => (
  <Box sx={{ ...sx }}>
    <InputLabel shirnk htmlFor={name}>
      <Typography
        sx={{
          color: '#A9B0BC',
          display: 'block',
        }}
        variant="buttonSmall"
        htmlFor={name}
      >
        {label}
      </Typography>
    </InputLabel>
    <TextField
      required
      fullWidth
      autoComplete="off"
      sx={{
        margin: 0,
        height: 'auto',
        '&:MuiInputBase-root': {
          height: 'fit-content',
        },
      }}
      margin="normal"
      error={!!error}
      multiline={multiline}
      value={value}
      id={name}
      name={name}
      helperText={error}
      onChange={onChange}
      InputProps={{
        startAdornment,
      }}
      {...rest}
    />
  </Box>
);
export default Input;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Hint from 'components/system/Hint';
import useGetSearchParam from 'hooks/useGetSearchParam';
import GoogleButton from 'assets/images/button-icons/googleButton.svg';
import { signInWithGoogle } from 'api/services/firebase';
import colors from 'styles/colors';
import { ForgotPassword } from '../PasswordRecovery';
import SignInForm from './SignInForm';

const styles = {
  container: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '348px',
    padding: 0,
  },
};

const SignInPopup = ({ handleSubmitCallback, hint, redirectToLinkedView }) => {
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate();
  const { useGetSearchParam: getSearchParam } = useGetSearchParam();
  const backUrl = getSearchParam('back');

  const handleGoogleAuth = async (e) => {
    await signInWithGoogle(e);
    navigate(backUrl);
  };

  const handleSubmit = (e) => {
    handleSubmitCallback(e);
    navigate(backUrl);
  };

  return (
    <Container component="main" maxWidth="xs" sx={styles.container}>
      <Box sx={styles.content}>
        {forgotPassword
          ? <ForgotPassword />
          : (
            <>
              {hint && <Hint>{hint}</Hint>}
              <Typography component="h1" variant="h5" color={colors.text.primary}>
                Login
              </Typography>
              <Button
                fullWidth
                type="secondary"
                variant="contained"
                onClick={handleGoogleAuth}
                sx={{ marginTop: '24px' }}
              >
                <GoogleButton />
                <Typography component="p" sx={{ paddingLeft: '12px' }}>
                  Sign in with Google
                </Typography>
              </Button>
              <SignInForm
                onSubmitCallback={handleSubmit}
                setForgotPassword={setForgotPassword}
                redirectToLinkedView={redirectToLinkedView}
              />
            </>
          )}
      </Box>
    </Container>
  );
};
export default SignInPopup;

import { useState } from 'react';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Input from 'components/system/Input';
import { sendPasswordReset } from 'api/services/firebase';
import colors from 'styles/colors';
import { forgotPasswordValidationSchema } from 'schemas/account.schema';
import ForgotPasswordNotification from './ForgotPasswordNotification';

const formInputLabels = {
  email: {
    label: 'Email',
  },
};

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [errors, setErrors] = useState({});

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const { success, error } = await sendPasswordReset(values);
      setLoading(false);
      if (success) {
        setIsEmailSent(true);
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: 'The email is invalid.',
        }));
      }
    },
  });

  return (
    <>
      <Typography variant="h5" color={colors.text.primary}>
        Forgot Password
      </Typography>
      {!isEmailSent ? (
        <Box component="form" sx={{ marginTop: '24px', width: '100%' }}>
          {Object.entries(formInputLabels)
            .map(([name, config]) => (
              <Input
                {...config}
                sx={{ marginBottom: '16px' }}
                autoComplete="off"
                key={name}
                value={formik.values.email}
                error={formik.touched[name] && formik.errors[name] || errors[name]}
                name={name}
                onChange={formik.handleChange}
              />
            ))}
          <LoadingButton
            fullWidth
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting}
            loadingPosition="end"
            onClick={formik.handleSubmit}
            variant="contained"
            type="primary"
            sx={{
              mt: 3,
              mb: 2,
            }}
          >
            Send
          </LoadingButton>
        </Box>
      ) : (
        <ForgotPasswordNotification />
      )}
    </>
  );
};

export default ForgotPassword;

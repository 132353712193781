import FontIcon from 'icomoon-react';
import iconSet from '../../../assets/icons.json';

const Icon = ({ color, size, name, sx, ...props}) => {
  return (
    <FontIcon
      {...props}
      style={sx}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={name}
    />
  );
};

export default Icon;

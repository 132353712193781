import Typography from '@mui/material/Typography';
import Check from 'assets/images/check.svg';
import colors from 'styles/colors';

const ResetPasswordNotification = () => (
  <Typography
    sx={{
      display: 'flex',
      alignItems: 'center',
      color: colors.text.primary,
      marginBottom: '30px',
    }}
    variant="body1"
  >
    <Check
      style={{
        width: '45px',
        height: '45px',
        marginRight: '5px',
      }}
      className="check"
    />
    Your Password has been successfully recovered.
  </Typography>
);

export default ResetPasswordNotification;

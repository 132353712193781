import { useState } from 'react';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Input from 'components/system/Input';
import Loading from 'components/system/Loading';
import colors from 'styles/colors';
import { resetPasswordValidationSchema } from 'schemas/account.schema';
import ResetPasswordNotification from './ResetPasswordNotification';

const formInputLabels = {
  password: {
    label: 'New Password',
    type: 'password',
  },
};

const PasswordRecovery = ({ recoverPassword }) => {
  const [loading, setLoading] = useState(false);
  const [isPasswordRecovered, setPasswordRecovered] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: resetPasswordValidationSchema,
    onSubmit: async ({ password }, formikHelpers) => {
      setLoading(true);
      const response = await recoverPassword(password);
      if (response?.errors) {
        formikHelpers.setErrors(response.errors); // needs testing
      } else {
        setPasswordRecovered(true);
      }
      setLoading(false);
    },
  });

  return (
    <Container sx={{ width: '450px', margin: '0 auto', marginTop: '60px' }}>
      {isPasswordRecovered
        ? (
          <ResetPasswordNotification />
        ) : (
          <>
            <Typography variant="h5" sx={{ color: colors.text.primary, marginBottom: '30px' }}>
              Reset your Password
            </Typography>
            <Box component="form" sx={{ width: '100%' }}>
              {Object.entries(formInputLabels)
                .map(([name, config]) => (
                  <Input
                    {...config}
                    sx={{ marginBottom: '16px' }}
                    autoComplete="off"
                    key={name}
                    value={formik.values.email}
                    error={formik.touched.password && formik.errors.password}
                    name={name}
                    onChange={formik.handleChange}
                  />
                ))}
              <Button
                onClick={formik.handleSubmit}
                fullWidth
                variant="contained"
                type="primary"
                sx={{
                  mt: 3,
                  mb: 2,
                  ...(loading ? {
                    backgroundColor: 'rgba(96, 94, 245, 0.5)',
                    color: colors.text.disabled,
                  } : {}),
                }}
                disabled={loading}
              >
                Submit
                {loading && <Loading sx={{ marginLeft: '5px' }} size={24} />}
              </Button>
            </Box>
          </>
        )}
    </Container>
  );
};

export default PasswordRecovery;

import { create as _create } from 'zustand';

const resetters = [];

export const create = (f) => {
  if (f === undefined) return create;

  const store = _create(f);
  const initialState = store.getState();

  resetters.push(() => {
    store.setState(initialState, true);
  });

  return store;
};

export const resetAllStores = () => {
  for (const resetter of resetters) {
    resetter();
  }
};

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Check from 'assets/images/check.svg';
import colors from 'styles/colors';

const ForgotPasswordNotification = () => (
  <>
    <Divider
      sx={{
        '&::before, &::after': {
          borderColor: colors.border.border_10p,
        },
        borderColor: colors.border.border_10p,
        width: '100%',
        margin: '24px 0',
      }}
    />
    <Typography
      sx={{ display: 'flex', alignItems: 'center' }}
      variant="body1"
      color={colors.text.secondary}
    >
      <Check
        style={{
          width: '45px',
          height: '45px',
          marginRight: '10px',
        }}
        className="check"
      />
      A mail has been sent to your email with further steps.
    </Typography>
  </>
);

export default ForgotPasswordNotification;

import { useSearchParams } from 'react-router-dom';

const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const useRemoveSearchParam = (paramName) => {
    searchParams.delete(paramName);
    setSearchParams(searchParams, { replace: true });
  };

  const useGetSearchParam = (paramName) => {
    const queryParam = searchParams.get(paramName);
    return queryParam;
  };

  return { useGetSearchParam, useRemoveSearchParam };
};

export default useQueryParams;

import { useState } from 'react';
import SignIn from './SignIn';
import SignUp from './SignUp';

const views = {
  signIn: {
    component: SignIn,
    linkedComponent: 'signUp',
  },
  signUp: {
    component: SignUp,
    linkedComponent: 'signIn',
  },
};

const AuthForm = ({ view, ...rest }) => {
  const [currentView, setCurrentView] = useState(view);
  const { component: Component, linkedComponent } = views[currentView];

  return (
    <Component
      {...rest}
      redirectToLinkedView={() => setCurrentView(linkedComponent)}
    />
  );
};

export default AuthForm;

import ColoredButton from 'components/system/ColoredButton';
import slackIcon from 'assets/images/slackIcon.png';

const slackJoinUrl = 'https://join.slack.com/t/bluequbit-app/shared_invite/zt-1r0pi4jvq-W~xnEd2UX0Pnq9dQPYAMTA';

const SlackButton = () => (
  <ColoredButton
    buttonSx={{ paddingTop: '5px', paddingBottom: '5px', fontSize: '14px' }}
    onClick={() => { window.open(slackJoinUrl, '_blank'); }}
  >
    <img alt="BQ slack" src={slackIcon} style={{ width: '32px', paddingRight: '3px' }} />
    Join Slack
  </ColoredButton>
);

export default SlackButton;

import { useCallback, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import EndIcon from 'assets/images/button-icons/AccountDownUpButton.svg';
import { getNameInitials } from 'utils/helpers';
import UserInfo from './UserInfo';
import './account.css';

const AccountAccess = ({ name, isVerified, isErrored }) => {
  const [open, setOpen] = useState(false);
  const [root, setRoot] = useState(null);
  const buttonRef = useRef(null);

  const nameInitials = name ? getNameInitials(name) : '';
  const id = open ? 'simple-popover' : undefined;

  const onMenuClick = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button
        ref={buttonRef}
        onClick={(e) => {
          setRoot(buttonRef.current);
          setOpen(true);
        }}
        type="secondary"
        endIcon={<EndIcon />}
        sx={{
          padding: '10px 20px 10px 0',
          height: '44px',
        }}
        disableRipple
      >
        <span className="accountUserName">{name}</span>
        <span className="accountUserInitials">{nameInitials}</span>
      </Button>
      <Popover
        id={id}
        open={open}
        onClose={() => {
          setOpen(false);
          setRoot(null);
        }}
        anchorEl={root}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <UserInfo isErrored={isErrored} isVerified={isVerified} onMenuClick={onMenuClick} />
      </Popover>
    </>
  );
};

export default AccountAccess;

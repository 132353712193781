import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import colors from 'styles/colors';
import { useModalContext } from '../ModalContext';

const Modal = () => {
  const {
    isOpened,
    content,
    closeModal,
    paperProps,
  } = useModalContext();

  return (
    <Dialog
      open={isOpened}
      onClose={closeModal}
      PaperProps={{ ...paperProps }}
    >
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: colors.text.primary,
        }}
      >
        <CloseIcon />
      </IconButton>
      {content}
    </Dialog>
  );
};

export default Modal;

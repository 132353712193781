import { useFormik } from 'formik';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import withNotification from 'components/hoc/withNotification';
import Input, { PasswordInput } from 'components/system/Input';
import { logInWithEmailAndPassword } from 'api/services/firebase';
import { signInValidationSchema } from 'schemas/account.schema';
import colors from 'styles/colors';

const formInputLabels = [
  {
    label: 'Email',
    name: 'email',
    component: Input,
  },
  {
    label: 'Password',
    type: 'password',
    name: 'password',
    component: PasswordInput,
  },
];

const SignInForm = ({
  onSubmitCallback, setForgotPassword, redirectToLinkedView, showErrorNotification,
}) => {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: signInValidationSchema,
    onSubmit: async (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      const response = await logInWithEmailAndPassword(values);
      formikHelpers.setSubmitting(false);
      if (response.errors) {
        formikHelpers.setErrors(response.errors);
      } else {
        onSubmitCallback();
      }
    },
  });

  return (
    <Box component="form" sx={{ width: '100%' }}>
      <Divider
        sx={{
          '&::before, &::after': {
            borderColor: colors.border.border_10p,
          },
          width: '100%',
          margin: '24px 0',
        }}
      >
        <Typography variant="helper" color={colors.text.secondary}>
          Or login with email
        </Typography>
      </Divider>
      {formInputLabels.map(({ component: InputComponent, name, ...config }) => (
        <InputComponent
          {...config}
          sx={{ marginBottom: '16px' }}
          autoComplete="off"
          key={name}
          value={formik.values[name]}
          error={formik.touched[name] && formik.errors[name]}
          name={name}
          onChange={formik.handleChange}
        />
      ))}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        sx={{ marginTop: '-12px' }}
      >
        <Link
          onClick={() => setForgotPassword(true)}
          variant="buttonSmall"
          sx={{ cursor: 'pointer', color: colors.primary.main }}
        >
          Forgot password?
        </Link>
      </Grid>
      <LoadingButton
        loading={formik.isSubmitting}
        disabled={formik.isSubmitting}
        type="submit"
        loadingPosition="end"
        onClick={formik.handleSubmit}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Sign In
      </LoadingButton>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item size="xs">
          <Typography variant="buttonSmall" color={colors.text.secondary}>
            New to BlueQubit?
            {' '}
            <Link
              variant="buttonSmall"
              sx={{
                cursor: 'pointer',
                color: colors.primary.main,
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={redirectToLinkedView}
            >
              Sign Up
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
export default withNotification(SignInForm);

import Typography from '@mui/material/Typography';
import colors from 'styles/colors';

const styles = {
  hint: {
    borderRadius: '16px',
    border: `1px solid ${colors.primary.shade_30p}`,
    backgroundColor: colors.primary.shade_16p,
    color: colors.text.secondary,
    padding: '6px 0',
    marginTop: '15px',
    marginBottom: '15px',
    width: '100%',
    textAlign: 'center',
  },
}

const Hint = ({ children }) => (
  <Typography component="p" variant="subtitle2" sx={styles.hint}>
    {children}
  </Typography>
);

export default Hint;

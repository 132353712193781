import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import App from 'components/app';

const container = document.getElementById('app');
const root = createRoot(container);

if (process.env.ENVIRONMENT === 'production') {
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_SDK_KEY,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production'],
  });

  BugsnagPerformance.start({
    apiKey: process.env.BUGSNAG_SDK_KEY,
    enabledReleaseStages: ['production'],
  });

  const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React);

  root.render(
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>,
  );
} else {
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
  );
}

const typography = {
  fontFamily: 'Satoshi',
  fontStyle: 'normal',
  h1: {
    fontWeight: '700',
    fontSize: '96px',
    lineHeight: '100px',
  },
  h2: {
    fontWeight: '700',
    fontSize: '60px',
    lineHeight: '64px',
  },
  h3: {
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '52px',
  },
  h4: {
    fontWeight: '700',
    fontSize: '36px',
    lineHeight: '42px',
  },
  h5: {
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '28px',
  },
  h6: {
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '24px',
  },
  subtitle1: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
  },
  subtitle2: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '18px',
  },
  body1: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
  },
  body2: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
  },
  body3: {
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '20px',
  },
  buttonLarge: {
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '22px',
  },
  button: {
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '20px',
  },
  buttonSmall: {
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '16px',
  },
  caption: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
  },
  overline: {
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '16px',
  },
  label: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '18px',
  },
  helper: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '16px',
  },
  input: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
  },
  chart: {
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: '12px',
  },
  cell: {
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '16px',
  },
  tooltip: {
    fontWeight: '400',
    fontSize: '10px',
    lineHeight: '12px',
  },
};

export default typography;

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import colors from 'styles/colors';

const SignUpNotification = () => (
  <>
    <Divider />
    <Typography
      sx={{ marginTop: '16px', textAlign: 'center', color: colors.text.secondary }}
      variant="body2"
    >
      Please click on the link we emailed you to complete your signup.
    </Typography>
  </>
);

export default SignUpNotification;

import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  auth,
  applyActionCode,
  verifyPasswordResetCode,
  logout,
  confirmPasswordReset, useAuthState,
} from 'api/services/firebase';
import { ResetPassword } from './PasswordRecovery';

const AuthStatusManager = () => {
  const [searchParams] = useSearchParams();
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const mode = searchParams.get('mode');
  const actionCode = searchParams.get('oobCode');

  // eslint-disable-next-line no-shadow
  const handleVerifyEmail = async (actionCode) => {
    try {
      if (user) {
        await logout();
      }
      await applyActionCode(auth, actionCode);
      navigate('/?open=login');
    } catch (error) {
      console.error(error);
    }
  };

  // eslint-disable-next-line no-shadow
  const handleResetPassword = async (actionCode, newPassword) => {
    try {
      const email = await verifyPasswordResetCode(auth, actionCode);
      const response = await confirmPasswordReset(auth, actionCode, newPassword);
      return response;
    } catch (error) {
      if (error.code === 'auth/invalid-action-code') {
        return {
          errors: {
            password: 'The link for password recovery has been expired',
          },
        };
      }
      console.log(error);
      return error;
    }
  };

  switch (mode) {
    case 'resetPassword':
      return (
        <ResetPassword
          recoverPassword={(password) => handleResetPassword(actionCode, password)}
        />
      );
    case 'verifyEmail':
      // Display email verification handler and UI.
      handleVerifyEmail(actionCode);
      // eslint-disable-next-line consistent-return
      return;
    default:
      // Error: invalid mode.
      // eslint-disable-next-line no-console
      console.error(`Error occurred: unknown firebase action - ${mode}`);
  }

  return <h1>{mode}</h1>;
};

export default AuthStatusManager;

import { Route, Routes } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { auth, useAuthState, sendEmailVerification } from 'api/services/firebase';
import withNotification from 'components/hoc/withNotification';
import colors from 'styles/colors';
import { AuthStatusManager } from '../Auth';

const UserVerification = ({ showInfoNotification }) => {
  const [user] = useAuthState(auth);
  const handleSendVerificationEmail = async () => {
    try {
      await sendEmailVerification(user);
      showInfoNotification(
        'Verification email sent. Please check your email for further steps.',
      );
    } catch {
      showInfoNotification(
        'Please wait a few minutes before trying again.',
      );
    }
  };

  return (
    <Grid container sx={{ width: '100%', height: '100%', marginTop: '60px' }}>
      <Grid item sx={{ width: '100%', margin: '0 50px', textAlign: 'center' }}>
        <Routes>
          <Route path="user-management" element={<AuthStatusManager />} />
        </Routes>
        <Typography
          variant="buttonLarge"
          sx={{ color: colors.text.secondary, paddingBottom: '15px', display: 'block' }}
        >
          Please verify your account in order to continue.
          {' '}
          Check your email
          {' '}
          <span data-testid="emailContainer" style={{ textDecoration: 'underline' }}>{user?.email}</span>
          {' '}
          for further steps.
        </Typography>
        <Typography
          variant="buttonLarge"
          sx={{ color: colors.text.secondary }}
        >
          If you did not receive an email, please check your spam folder or
          <Button
            type="primary"
            variant="contained"
            sx={{ marginLeft: '10px' }}
            onClick={handleSendVerificationEmail}
          >
            Resend the verification email
          </Button>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withNotification(UserVerification);

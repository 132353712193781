import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Input, { PasswordInput } from 'components/system/Input';
import GoogleButton from 'assets/images/button-icons/googleButton.svg';
import { registerWithEmailAndPassword, signInWithGoogle } from 'api/services/firebase';
import { signUpValidationSchema } from 'schemas/account.schema';
import colors from 'styles/colors';
import SignUpNotification from './SignUpNotification';

const formInputLabels = [
  {
    name: 'name',
    label: 'Full Name',
    component: Input,
  },
  {
    name: 'email',
    label: 'Email',
    component: Input,
  },
  {
    name: 'password',
    label: 'Password',
    type: 'password',
    component: PasswordInput,
  },
];

const SignUpForm = ({ redirectToLinkedView }) => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
    },
    validationSchema: signUpValidationSchema,
    onSubmit: async (values, formikHelpers) => {
      const response = await registerWithEmailAndPassword(values);
      if (response.errors) {
        formikHelpers.setErrors(response.errors);
      } else {
        setIsEmailSent(true);
      }
    },
  });

  const handleGoogleSingUp = async () => {
    await signInWithGoogle();
    navigate({
      search: '?sign_up=google',
    });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ paddingLeft: '0 !important', paddingRight: '0 !important' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '348px',
          padding: 0,
        }}
      >
        <Typography component="h1" variant="h5" color={colors.text.primary}>
          { !isEmailSent ? 'Sign up' : 'Please Verify your email'}
        </Typography>
        {
          !isEmailSent ? (
            <>
              <Button
                fullWidth
                type="secondary"
                variant="contained"
                onClick={handleGoogleSingUp}
                sx={{ marginTop: '24px' }}
              >
                <GoogleButton />
                <Typography component="p" sx={{ paddingLeft: '12px' }}>
                  Sign up with Google
                </Typography>
              </Button>
              <Box component="form" sx={{ width: '100%', marginTop: '16px' }}>
                {formInputLabels.map(({ component: InputComponent, name, ...config }) => (
                  <InputComponent
                    {...config}
                    sx={{ marginBottom: '16px', width: '100%' }}
                    autoComplete="off"
                    key={name}
                    value={formik.values[name]}
                    error={formik.touched[name] && formik.errors[name]}
                    name={name}
                    onChange={formik.handleChange}
                  />
                ))}
                <Typography
                  sx={{
                    display: 'block',
                    margin: '10px',
                  }}
                  variant="buttonSmall"
                  color={colors.text.secondary}
                >
                  By creating a BlueQubit account,
                  you agree to BlueQubit&apos;s  &nbsp;
                  <Link
                    onClick={() => window.open('terms-of-use', '_blank')}
                    variant="buttonSmall"
                    sx={{ cursor: 'pointer', color: colors.primary.main }}
                  >
                    Terms
                  </Link>
                  {' '}
                  and
                  {' '}
                  <Link
                    onClick={() => window.open('privacy-policy', '_blank')}
                    variant="buttonSmall"
                    sx={{ cursor: 'pointer', color: colors.primary.main }}
                  >
                    Privacy Policy
                  </Link>
                </Typography>
                <LoadingButton
                  fullWidth
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                  type="submit"
                  loadingPosition="end"
                  onClick={formik.handleSubmit}
                  variant="contained"
                  sx={{ mb: 2 }}
                >
                  Sign Up
                </LoadingButton>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item size="xs">
                    <Typography variant="buttonSmall" color={colors.text.secondary}>
                      Already have an account?
                      {' '}
                      <Link
                        variant="buttonSmall"
                        sx={{
                          cursor: 'pointer',
                          color: colors.primary.main,
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}
                        onClick={redirectToLinkedView}
                      >
                        Sign In
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <SignUpNotification />
          )
        }
      </Box>
    </Container>
  );
};
export default SignUpForm;

import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getAccountData } from 'api/services/account';
import { auth, useAuthState } from 'api/services/firebase';
import useAccountStore from '../store/accountStore';

const useMe = (shouldRefetch) => {
  const [retryCount, setRetryCount] = useState(0);
  const [user] = useAuthState(auth);
  const queryClient = useQueryClient();
  const isUserAvailable = !!user && user.emailVerified;

  const setAccount = useAccountStore((state) => state.setAccount);

  const {
    data = {}, isLoading, error, refetch,
  } = useQuery({
    queryKey: 'account',
    queryFn: getAccountData(),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isUserAvailable,
    onSuccess: (responseData) => {
      setAccount({
        ...responseData,
        isLoading: false,
      });
    },
    onError: (err) => {
      if (
        err?.response?.status === 401
        && err.response.data.errorMessage.includes('specified team')
        && retryCount < 5) {
        setRetryCount((count) => count + 1);
        localStorage.removeItem(`accountMode_${user.uid}`);
        refetch();
      } else {
        setAccount({
          error: err,
          isLoading: false,
        });
      }
    },
  });

  const refetchGetMe = async (...args) => {
    if (shouldRefetch) {
      queryClient.removeQueries({ queryKey: ['account'] });
    }
    await refetch(...args);
  };

  return {
    refetch: refetchGetMe,
  };
};

export default useMe;

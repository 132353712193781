import {
  JobTerminatedStatusIcon,
  JobProgressStatusIcon,
  JobCompletedStatusIcon,
} from '../components/pages/Jobs/JobStatusIcons';

export const JOB_CANCELED_STATUS = 'CANCELED';
export const JOB_TERMINATED_STATUS = 'TERMINATED';
export const JOB_NOT_ENOUGH_FUNDS_STATUS = 'NOT_ENOUGH_FUNDS';
export const JOB_LIMIT_EXCEEDED_STATUS = 'JOBS_LIMIT_EXCEEDED';
export const JOB_FAILED_VALIDATION_STATUS = 'FAILED_VALIDATION';
export const JOB_COMPLETED_STATUS = 'COMPLETED';
export const JOB_PENDING_STATUS = 'PENDING';
export const JOB_QUEUED_STATUS = 'QUEUED';
export const JOB_RUNNING_STATUS = 'RUNNING';

export const jobInProgressStatuses = {
  JOB_PENDING_STATUS,
  JOB_QUEUED_STATUS,
  JOB_RUNNING_STATUS,
};

export const jobCompletedStatuses = {
  JOB_CANCELED_STATUS,
  JOB_TERMINATED_STATUS,
  JOB_NOT_ENOUGH_FUNDS_STATUS,
  JOB_COMPLETED_STATUS,
  JOB_LIMIT_EXCEEDED_STATUS,
};

export const jobStatusIcons = {
  [JOB_CANCELED_STATUS]: JobTerminatedStatusIcon,
  [JOB_TERMINATED_STATUS]: JobTerminatedStatusIcon,
  [JOB_NOT_ENOUGH_FUNDS_STATUS]: JobTerminatedStatusIcon,
  [JOB_FAILED_VALIDATION_STATUS]: JobTerminatedStatusIcon,
  [JOB_LIMIT_EXCEEDED_STATUS]: JobTerminatedStatusIcon,
  [JOB_COMPLETED_STATUS]: JobCompletedStatusIcon,
  [JOB_PENDING_STATUS]: JobProgressStatusIcon,
  [JOB_QUEUED_STATUS]: JobProgressStatusIcon,
  [JOB_RUNNING_STATUS]: JobProgressStatusIcon,
};

export const jobStatusLabels = {
  [JOB_CANCELED_STATUS]: 'Canceled',
  [JOB_TERMINATED_STATUS]: 'Terminated',
  [JOB_NOT_ENOUGH_FUNDS_STATUS]: 'Not enough funds',
  [JOB_LIMIT_EXCEEDED_STATUS]: 'Jobs limit exceeded',
  [JOB_COMPLETED_STATUS]: 'Completed',
  [JOB_PENDING_STATUS]: 'Pending',
  [JOB_QUEUED_STATUS]: 'Queued',
  [JOB_RUNNING_STATUS]: 'In progress',
  [JOB_FAILED_VALIDATION_STATUS]: 'Failed validation',
};

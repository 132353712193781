import * as yup from 'yup';

const passwordSchema = yup
  .string()
  .min(8, 'Password should be of minimum 8 characters length')
  .max(30, 'Password should be of no more than 30 characters length')
  .matches(/[0-9]/, 'Password requires a number')
  .matches(/[a-z]/, 'Password requires a lowercase letter')
  .matches(/[A-Z]/, 'Password requires an uppercase letter')
  .matches(/[^\w]/, 'Password requires a symbol')
  .required('Password is required');

const passwordSimpleSchema = yup
  .string()
  .required('Password is required');

const emailSchema = yup
  .string()
  .email('Email is not valid')
  .required('Email is required');

const nameSchema = yup
  .string()
  .required('Full name is required');

export const signUpValidationSchema = yup.object({
  name: nameSchema,
  email: emailSchema,
  password: passwordSchema,
});

export const signInValidationSchema = yup.object({
  email: emailSchema,
  password: passwordSimpleSchema,
});

export const forgotPasswordValidationSchema = yup.object({
  email: emailSchema,
});

export const resetPasswordValidationSchema = yup.object({
  password: passwordSchema,
});

export const accountSettingsSchema = (type) => ({
  fullname: yup.object({
    firstName: yup.string().required('Your firstname is required'),
    lastName: yup.string().required('Your lastname is required'),
  }),
  email: yup.object({
    email: emailSchema,
  }),
  password: yup.object({
    password: passwordSchema,
    confirmPassword: yup.string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  }),
})[type];

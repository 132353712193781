import { useCallback } from 'react';
import { useModalContext } from 'components/system/ModalContext';
import { AuthForm } from 'components/pages/Auth';
import useQueryParams from 'hooks/useGetSearchParam';

const withSignUpDialog = (Component) => ({ ...props }) => {
  const { openModal, closeModal } = useModalContext();
  // const { useRemoveSearchParam } = useQueryParams();

  // const handleClose = useCallback(
  //   () => useRemoveSearchParam('open'),
  //   [useRemoveSearchParam],
  // );

  const openSignUpDialog = () => {
    openModal({
      content: (
        <AuthForm
          view="signUp"
          handleSubmitCallback={closeModal}
        />
      ),
      // onCloseCallback: handleClose,
    });
  };

  return (

    <Component
      {...props}
      openSignUpDialog={openSignUpDialog}
    />
  );
};

export default withSignUpDialog;
